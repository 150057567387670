import React, { useEffect, useState } from "react";
import "../userDataDetails.scss"
import "../react-select.scss";
import { useAdminStore } from "store/adminStore";
import UserProfileImage from "components/UserImage";
import { DEFAULTUSERCOLUMN, Sort } from "utils/constants";
import SortButton from "../../../../../../components/sortButton"
import PageLoader from "components/common-components/PageLoader";
import { useGetQueryBuilderDefaultUsers } from "api/admin/useQueryBuilderDefaultUser";
import CommonTable from "../CommonTable";

interface Props {
    tabSwitch?: any;
    onUpdateDefaultUserList?: any;
    setUserId?: any;
    setSkillState?: any;
    setLoading?: any;
    setLimit?: any;
}

type SortStatus = {
    [key: string]: string;
};

type SortStatusKeys = keyof SortStatus;

const UserDefaultDataDetails: React.FC<Props> = ({ tabSwitch, onUpdateDefaultUserList, setUserId, setSkillState, setLoading, setLimit }) => {
    const {
        setSortOptionDefaultSkills,
        filterToggle
    } = useAdminStore();
    const { isLoading: defaultUserLoading, data: defaultUserList } = useGetQueryBuilderDefaultUsers();

    const sortByColumnsInitialState: SortStatus = {
        sortBy: "disabled",
        sortByEmployee: "asc",
        sortByDesignation: "disabled",
        sortBySkillname: "disabled",
        sortByDepartment: "disabled",
        sortBySkillType: "disabled",
        sortBySkillCount: "disabled",
        sortByCertificationCount: "disabled",
        sortByDomainCount: "disabled",
    };

    const [sortByColumn, setSortByColumn] = useState<SortStatus>(
        sortByColumnsInitialState
    );

    const sortByOnClick = (column: SortStatusKeys, columnNo: number) => {
        const sortOrder =
            sortByColumn[column] === "asc" ? "desc" : "asc";
        const updatedSortByColumn = {
            ...Object.fromEntries(
                Object.keys(sortByColumn).map((key) => [key, "disabled"])
            ),
            [column]: sortOrder,
        };
        setSortByColumn(updatedSortByColumn);
        setSortOptionDefaultSkills(Sort[columnNo * 2 + (sortOrder === "asc" ? 0 : 1)]);
    };

    const openmodal = () => {
        setSkillState(true)
    }

    useEffect(() => {
        if (!defaultUserLoading) {
            onUpdateDefaultUserList(defaultUserList);
            setLimit(defaultUserList?.limit)
        }
    }, [defaultUserLoading, defaultUserList, onUpdateDefaultUserList]);

    useEffect(() => {
        setSortByColumn(sortByColumnsInitialState)
    }, [filterToggle])

    const renderSortButton = (
        column: SortStatusKeys,
        index: number
    ) => (
        <SortButton
            isAscending={sortByColumn[column]}
            onClick={() => sortByOnClick(column, index)}
            icon="#sortIcon"
        />
    );

    const renderDefaultUser = (isMobile: boolean = false) => {
        const defaultUsers = defaultUserList?.users || [];
        const offset = defaultUserList?.offset || 0;

        if (defaultUserLoading || defaultUsers.length === 0) {
            return <PageLoader />;
        }

        return defaultUsers.map((item: any, index: number) => {
            if (isMobile) {
                return (
                    <div key={index} className="user-skill-details">
                        <div className="image-section" >
                            <button className="icon-only" onClick={() => { openmodal(); setUserId(item.id); setLoading(true) }}>
                                <UserProfileImage first_name={item.first_name} ssoId={item.sso_login_id} />
                            </button>
                        </div>
                        <div className="user-detail-section">
                            <span className="user-title">{item.first_name} {item.last_name}</span>
                            <div className="user-sub-details">
                                <span className="user-id">Id:{item.employee_id}</span>
                                <span className="user-designation">{item.designation}</span>
                            </div>
                            <span className="skill-level">Primary</span>
                            <div className='box-holder'>
                                <span className='left-section' > {item.skill_name === null ? 'NA' : item.skill_name}</span>
                                <span className='right-section'> {item.skill_rank === null ? 'NA' : item.skill_rank}</span>
                            </div>
                            <div className="user-sub-details">
                                <span className="user-id">Skill Count: {item.skills_count}</span>
                            </div>
                            <div className="user-sub-details">
                                <span className="user-id">Certification Count: {item.certifications_count}</span>
                            </div>
                            {/* <div className="user-sub-details">
                                <span className="user-id">Domain Count: {item.domains_count}</span>
                            </div> */}
                        </div>
                    </div>
                );
            } else {
                return (
                    <tr key={index}>
                        <td>{offset + index + 1}</td>
                        <td>{item.employee_id}</td>
                        <td>
                            <span className="userwrapper">
                                <div className="profile-img-holder">
                                    <button
                                        className="icon-only"
                                        onClick={() => {
                                            openmodal();
                                            setUserId(item.id);
                                            setLoading(true);
                                        }}
                                    >
                                        <UserProfileImage
                                            first_name={item.first_name}
                                            ssoId={item.sso_login_id}
                                        />
                                    </button>
                                </div>
                                <span>
                                    {item.first_name} {item.last_name}
                                </span>
                            </span>
                        </td>
                        <td>{item.designation}</td>
                        <td>{item.department || "-"}</td>
                        <td>{item.skills_count || "-"}</td>
                        <td>{item.certifications_count || "-"}</td>
                        {/* <td>{item.domains_count || "-"}</td> */}
                        <td>{item.skill_name || "-"}</td>
                        <td>{item.skill_rank || "-"}</td>
                    </tr>
                );
            }
        });
    };

    return (
        <>
            <div className="user-details-Wrappper">
                <div className="user-details-Table ">
                    {defaultUserLoading ? (
                        <PageLoader />
                    ) : (
                        <CommonTable
                            columns={DEFAULTUSERCOLUMN}
                            data={defaultUserList?.users}
                            renderSortButton={renderSortButton}
                            renderRow={renderDefaultUser}
                            placeholder={" No User(s) Found"}
                        />
                    )}
                </div>
            </div>
            <div className="user-details-mobile-Wrappper">
                <div className="user-section">
                    {renderDefaultUser(true)}
                </div>
            </div>
        </>
    );
};
export default UserDefaultDataDetails;