import React from "react";
import "./confirm-delete-modal.scss";

const ConfirmDeleteModal = () => {
  return (
    <div className="app-modal-content confirm-delete-popup">
      <p className="sub-content">Are you sure that you want to delete this skill?.</p>
    </div>
  );
};

export default ConfirmDeleteModal;

